var $ = require('./vendor/jquery-2.1.3.min');
var storage = require('./storage');
var refs = require('./refs.json');

module.exports = function() {

var dimensions = 10,
  viewport = $('#gamecontent'),
  polediv = viewport.children('#pole'),
  curentdiv = [ viewport.find('#next0'), viewport.find('#next1') ],
  scorediv = viewport.find('#score'),
  maxscorediv = viewport.find('#maxscore'),

  pole = [],
  userquery = [],
  curents = [],
  score = 0,
  maxscore = 0,
  mousedown = false;

function setScore(s) {
  scorediv.html(s);
}

function setMaxScore(s) {
  maxscorediv.html(s);
}

function newBlock(container) {
  var block = $('<div class="tactris-block"></div>').appendTo(container);

  block.on('mousedown', function(e) {
    if (block.logicObject.state != 'placed') {
      block.logicObject.setState('active');
    }
  });
  block.on('mouseenter', function(e) {
    e.preventDefault();
    if (mousedown) {
      if (block.logicObject.state != 'placed') {
        block.logicObject.setState('active');
      }
    }
    e.stopPropagation();
  });
  block.on('touchstart', function(e) {
    e.preventDefault();
    if (block.logicObject.state != 'placed') {
      block.logicObject.setState('active');
    }
  });

  //change visual state
  block.go = function(state) {
    block.removeClass('active');
    block.removeClass('placed');
    if (state != 'empty') {
      block.addClass(state);
    }
  }

  block.setTo = function(logicObject) {
    this.logicObject = logicObject;
    var blocksize = parseInt(polediv.width()) / dimensions - 1;
    var offset = blocksize + 1;
    block.css({
      'top': logicObject.y * offset + 'px',
      'left': logicObject.x * offset + 'px',
      'width': blocksize,
      'height': blocksize
    });
  }

  return block;
}

function initPole() {
  pole = [];
  for (j = 0; j < dimensions; j++) {
    var line = [];
    pole.push(line);
    for (i = 0; i < dimensions; i++) {
      var block = {
        x: i,
        y: j,
        state: 'empty',
        div: newBlock(polediv),
        setState: function(state) {
          if (state != this.state) {
            this.state = state;
            this.div.go(state);
            if (state === 'active') {
              userquery.push(this);
              checkFigure();
            }
          }
        },
        setPosition: function(x, y) {
          this.x = x;
          this.y = y;
          this.div.setTo(this);
        }
      };
      block.div.setTo(block);
      line.push(block);
    }
  }
}

function init() {
  polediv.html('');
  polediv.width(polediv.width());
  polediv.height(polediv.width());

  polediv.on('contextmenu', function(e) {
    e.preventDefault();
  });
  polediv.on('mousedown', function(e) {
    mousedown = true;
  });
  $(this).on('mouseup', function(e) {
    mousedown = false;
    checkFigure();
  });

  score = 0;
  setScore(score);

  maxscore = storage.get('tactris.maxscore');
  maxscore = (maxscore > 0) ? maxscore : 0;
  setMaxScore(maxscore);

  initPole();

  for (var a = 0; a < 2; a++) {
    curents.push();
    setCurrent(a);
  }

  var currentState = storage.get('tactris.state');
  if (currentState) {
    setState(currentState);
  } else {
    storage.set('tactris.state', getState());
  }

  var lastState = storage.get('tactris.laststate');
  if (!lastState) {
    storage.set('tactris.laststate', getState());
  }
}

function setState(state) {
  for (j = 0; j < dimensions; j++) {
    for (i = 0; i < dimensions; i++) {
      pole[j][i].setState(state.pole[j][i]);
    }
  }

  score = state.score;
  setScore(score);
  setCurrent(0, state.nexts[0]);
  setCurrent(1, state.nexts[1]);

  if (checkEnd()) {
    showEnd();
  }
}

function getState() {
  var state = {};
  state.pole = [];
  for (j = 0; j < dimensions; j++) {
    var line = [];
    state.pole.push(line);
    for (i = 0; i < dimensions; i++) {
      line.push(pole[j][i].state);
    }
  }
  state.score = score;
  state.nexts = [curents[0].refindex, curents[1].refindex];

  return state;
}

function checkLines() {
  var outlines = [];
  for (var j in pole) {
    var xcounter = 0;
    var ycounter = 0;

    for (var i in pole[j]) {
      if (pole[j][i].state === 'placed') {
        xcounter++;
      }
      if (pole[i][j].state === 'placed') {
        ycounter++;
      }
    }
    if (xcounter == dimensions) {
      outlines.push({
        dir: 'x',
        line: parseInt(j)
      });
    }
    if (ycounter == dimensions) {
      outlines.push({
        dir: 'y',
        line: parseInt(j)
      });
    }
  }
  for (var out in outlines) {
    var line = outlines[out];
    if (line.dir === 'x') {
      //empty line
      for (var i in pole[line.line]) {
        var block = pole[line.line][parseInt(i)];
        block.setState('empty');
      }
      //rearange array
      var shift = 0;
      if (line.line > 4) {
        pole.push(pole.splice(line.line, 1)[0]);
        shift = 1;
      } else {
        pole.unshift(pole.splice(line.line, 1)[0]);
        shift = -1;
      }
    } else {
      for (var i in pole[line.line]) {
        var ivar = parseInt(i);
        var block = pole[ivar][line.line];
        block.setState('empty');

        var f = pole[ivar].splice(line.line, 1)[0];
        if (line.line > 4) {
          pole[ivar].push(f);
        } else {
          pole[ivar].unshift(f);
        }
      }
    }
    for (var a in pole) {
      for (var i in pole[a]) {
        var b = parseInt(i);
        pole[a][b].setPosition(b, a);
      }
    }
    for (var last = parseInt(out) + 1; last < outlines.length; last++) {
      var nextline = outlines[last];
      if (nextline.dir === line.dir) {
        if (nextline.line > 4 && line.line > 4) {
          nextline.line -= 1;
        }
      }
    }
    score += 10 * outlines.length;
    setScore(score);
  }
}

function checkEnd() {
  for (var f in curents) {
    var curent = curents[f].figure;
    for (var x in pole) {
      for (var y in pole[x]) {
        var counter = 0;
        for (var i in curent) {
          var cx = curent[i].y;
          var cy = curent[i].x;
          var tx = parseInt(y);
          var ty = parseInt(x);
          if (cx + tx < dimensions && cy + ty < dimensions) {
            if (pole[cx + tx][cy + ty].state != 'placed') {
              counter += 1;
            }
          }
        }
        if (counter === 4) {
          return false;
        }
      }
    }
  }
  return true;
}

//check user query for similarity
function checkFigure() {
  var checkindex = null;

  var instal = function() {
    for (var a in userquery) {
      var block = userquery[a];
      block.setState('placed');
    }
    userquery = [];
    setCurrent(checkindex);
    checkLines();
    if (checkEnd()) {
      showEnd();
    }

    var currentState = getState();
    var lastState = storage.get('tactris.state');
    storage.set('tactris.state', currentState);
    storage.set('tactris.laststate', lastState);
  }

  var ok = function(sx, sy) {
    for (var a in curents) {
      var curent = curents[a].figure;
      var counter = 0;
      for (var b in userquery) {
        for (var d in curent) {
          if (((userquery[b].x - sx) == curent[d].x) && ((userquery[b].y - sy) == curent[d].y)) {
            counter++;
          }
        }
      }
      if (counter == userquery.length) {
        checkindex = a;
        return true;
      }
    }
    return false;
  }
  if (userquery.length > 1) {
    var lowx = dimensions;
    var lowy = dimensions;
    for (var a in userquery) {
      var block = userquery[a];
      if (block.x < lowx) {
        lowx = block.x;
      }
      if (block.y < lowy) {
        lowy = block.y;
      }
    }
    if (ok(lowx, lowy) || ok(lowx - 1, lowy) || ok(lowx, lowy - 1) || ok(lowx - 2, lowy) || ok(lowx, lowy - 2)) {
      if (!mousedown && userquery.length == 4) {
        score += 4;
        setScore(score);
        instal();
      }
    } else {
      userquery.shift().setState('empty');
    }
  }
}

function randomFigureIndex() {
  var holyrandom = Math.round(Math.random() * (refs.length - 1));
  if (curents.length == 2) {
    if (curents[1].refindex == holyrandom || curents[0].refindex == holyrandom) {
      holyrandom = randomFigureIndex();
    }
  }
  return holyrandom;
}

function newCurrentBlock(container, x, y) {
  var block = $('<div class="tactris-block"></div>').appendTo(container);

  block.set = function(x, y) {
    var blocksize = parseInt(curentdiv[0].width()) / 4 - 1;
    var offset = blocksize + 1;
    block.css({
      'top': y * offset + 'px',
      'left': x * offset + 'px',
      'width': blocksize,
      'height': blocksize
    });
  }

  return block;
}

function setCurrent(a, index) {
  if (index === undefined) {
    var index = randomFigureIndex();
  }

  var nxt = curents[a] || {};
  nxt.figure = refs[index];
  nxt.refindex = index;

  if (curents[a]) {
    for (var i in nxt.figure) {
      nxt.fig[i].set(nxt.figure[i].x, nxt.figure[i].y);
    }
  } else {
    nxt.fig = [];

    var figurecontainer = curentdiv[a];
    for (var i in nxt.figure) {
      nxt.fig.push(newCurrentBlock(figurecontainer));
      nxt.fig[i].set(nxt.figure[i].x, nxt.figure[i].y);
    }
  }

  curents[a] = nxt;

  return nxt;
}

function newGame() {
  hideEnd();

  // Save maxscore
  maxscore = (score > maxscore) ? score : maxscore;
  storage.set('tactris.maxscore', maxscore);

  // Reset saved data
  storage.set('tactris.laststate', false);
  storage.set('tactris.state', false);
  init();
}

function undo() {
  hideEnd();

  var currentState = storage.get('tactris.state');
  var lastState = storage.get('tactris.laststate');
  if (lastState) {
    setState(lastState);
    storage.set('tactris.laststate', currentState);
    storage.set('tactris.state', lastState);
  }
}

function hideEnd() {
  $('#gameover').hide();
}

function showEnd() {
  $('#gameover').show();
}

init();

// UI

$('#gameover').on('click touchend', function(e) {
  e.preventDefault();
  newGame();
});

$('#reset').on('click touchend', function(e) {
  e.preventDefault();
  if(confirm('Вы уверены?')) {
    newGame();
  }
});

$('#restore').on('click touchend', function(e) {
  e.preventDefault();
  undo();
});

}
