"use strict";

var storage = {
  get: function(key) {
    var retrievedObj = null;
    try {
      var value = localStorage.getItem(key)
      if (value) {
        retrievedObj = JSON.parse(value);
      }
    } catch (e) {}
    return retrievedObj;
  },

  set: function(key, value) {
    try {
      var stringify = JSON.stringify(value);
      localStorage.setItem(key, stringify);
    } catch (e) {}
    return value;
  },

  remove: function(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {}
  }
};

module.exports = storage;
