"use strict";

var $ = require('./vendor/jquery-2.1.3.min');
var tactris = require('./tactris');

/*$(document).ready(function () {
  function reorient(e) {
    var portrait = (window.orientation % 180 == 0);
    if (!portrait) {
      $("#viewport").attr("content", "height=500, user-scalable=no"); 
    } else {
      $("#viewport").attr("content", "width=500, user-scalable=no");
    }
  }
  window.onorientationchange = reorient;
  window.setTimeout(reorient, 0);
});*/

$(function() {
  //$('#pole').width($(window).height());
  tactris();
});
